export default [
  // ['de_DE', 'Deutsch'],
  // ['el_GR', 'ελληνικά'],
  ['es_ES', 'Español'],
  // ['fil_PH', 'Filipino'],
  // ['fr_FR', 'Français'],
  // ['hr_HR', 'hrvatski jezik'],
  // ['id_ID', 'Indonesian'],
  // ['it_IT', 'Italiano'],
  // ['ja_JP', '日本語'],
  ['ko_KR', '한국어'],
  // ['nl_NL', 'Nederlands'],
  // ['pt_PT', 'Português'],
  // ['ro_RO', 'limba română'],
  // ['ru_RU', 'Русский'],
  // ['th_TH', 'ไทย'],
  // ['tr_TR', 'Türkçe'],
  // ['uk_UA', 'Українська'],
  // ['vi_VN', 'Tiếng Việt'],
  ['zh_CN', '简体中文'],
  // ['zh_TW', '繁體中文'],
  ['en_US', 'English']
]
